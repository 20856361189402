import './vendor/gsap.min.js'
import './vendor/ScrollTrigger.min.js'
import './tictoc/environment.js.erb'
import './tictoc/preview.js'
import './tictoc/ui.js'
import './agescotland/menu.js.coffee'
import './agescotland/cookie_notice.js.coffee'
import './agescotland/video.js.coffee'
import './agescotland/accordion.js.coffee'
import './agescotland/custom_select.js.coffee'
import './agescotland/custom_radio.js.coffee'
import './agescotland/custom_checkbox.js.coffee'
import './agescotland/masks.js.coffee'
import './agescotland/publication.js.coffee'
import './agescotland/bag.js.coffee'
import './agescotland/stripe.js'
import './agescotland/form_show_hide.js.coffee'
import './agescotland/nested_forms.js.coffee'
import './agescotland/checkout.js.coffee'
import './agescotland/donate.js.coffee'
import './agescotland/tabs.js.coffee'
import './agescotland/attendee_fields.js.coffee'
import './agescotland/image_block.js.coffee'
import './agescotland/nested_form_count.js.coffee'
import './agescotland/gallery.js.coffee'
import './agescotland/button_text.js.coffee'

var ready;

ready = function (callback) {
  if (document.readyState !== 'loading') {
    callback();
  } else if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', callback);
  } else {
    document.attachEvent('onreadystatechange', function () {
      if (document.readyState === 'complete') {
        callback();
      }
    });
  }
};

ready(function () {
  tictoc.preview.setup();
  tictoc.ui.setup();
  agescotland.menu.setup();
  agescotland.cookie_notice.setup();
  agescotland.video.setup();
  agescotland.accordion.setup();
  agescotland.custom_select.setup();
  agescotland.custom_checkbox.setup();
  agescotland.custom_radio.setup();
  agescotland.masks.setup();
  agescotland.publication.setup();
  agescotland.bag.setup();
  agescotland.stripe.setup();
  agescotland.form_show_hide.setup();
  agescotland.nested_forms.setup();
  agescotland.checkout.setup();
  agescotland.donate.setup();
  agescotland.tabs.setup();
  agescotland.attendee_fields.setup();
  agescotland.image_block.setup();
  agescotland.nested_form_count.setup();
  agescotland.gallery.setup();
  agescotland.button_text.setup();
});