window.agescotland ||= {}

agescotland.button_text =
  setup: ->
    @add_listeners()

  add_listeners: ->
    fields = document.querySelectorAll('.button_text')

    return false unless fields

    fields.forEach (field) ->
      radioButtons = field.querySelectorAll('.radio_button:not(.detail_field .radio_button)')
      if radioButtons
        radioButtons.forEach (button) ->
          if button.checked
            agescotland.button_text.changeButton(field, button.value)
          button.addEventListener 'change', (e) =>
            agescotland.button_text.changeButton(field, e.target.value)


  changeButton: (field, value) ->
    btn = document.querySelector('.button')
    if value && field.dataset.detailOptions.includes(value)
      btn.innerHTML = field.dataset.buttonFalseText
    else
      btn.innerHTML = field.dataset.buttonTrueText